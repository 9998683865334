require('./src/styles/Prism.css')

function removeURLParameter(url, parameter) {
  const urlparts = url.split('?')
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '='
    const pars = urlparts[1].split(/[&;]/g)

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1)
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '')
  }
  return url
}

exports.onClientEntry = () => {
  window.onload = () => {
    // If adblocker is on, remove adgroupid param to prevent it from blocking
    // page load
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if ('adgroupid' in params && typeof window.__adroll == 'undefined') {
      const newURL = removeURLParameter(window.location.href, 'adgroupid')
      const entryPageProps = JSON.stringify({
        isEntryPage: true,
        name: document.title,
        path: document.location.pathname,
        referrer: document.referrer,
        title: document.title,
        url: document.URL
      })
      window.sessionStorage.setItem('entryPageProps', entryPageProps)
      window.location.replace(newURL)
    }
  }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('$pageview', arguments)
  if (window.posthog) {
    window.posthog.capture('$pageview')
  }
  if (window.plausible) {
    window.plausible('pageview')
  }

  // if (window._iub) {
  //   window._iub.cs.api.activateSnippets()
  // }

  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(
      () => window.scrollTo({ behavior: 'instant', left: 0, top: 0 }),
      0
    )
  }
}

exports.shouldUpdateScroll = ({
  getSavedScrollPosition,
  routerProps: { location }
}) => {
  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: 'smooth' })
    } else {
      // when using the browser back/forward buttons or navigating to a
      // different page client-side, instant scroll to its last saved position
      window.scrollTo({
        behavior: 'instant',
        left: currentPosition[0],
        top: currentPosition[1]
      })
    }
  }, 250)

  // do not let gatsby do anything more
  return false
}
